exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-default-template-js": () => import("./../../../src/templates/DefaultTemplate.js" /* webpackChunkName: "component---src-templates-default-template-js" */),
  "component---src-templates-featured-template-js": () => import("./../../../src/templates/FeaturedTemplate.js" /* webpackChunkName: "component---src-templates-featured-template-js" */),
  "component---src-templates-news-template-js": () => import("./../../../src/templates/NewsTemplate.js" /* webpackChunkName: "component---src-templates-news-template-js" */),
  "component---src-templates-recipe-template-js": () => import("./../../../src/templates/RecipeTemplate.js" /* webpackChunkName: "component---src-templates-recipe-template-js" */),
  "component---src-templates-restaurant-template-js": () => import("./../../../src/templates/RestaurantTemplate.js" /* webpackChunkName: "component---src-templates-restaurant-template-js" */),
  "component---src-templates-thing-to-do-template-js": () => import("./../../../src/templates/ThingToDoTemplate.js" /* webpackChunkName: "component---src-templates-thing-to-do-template-js" */)
}

